<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { identityStore } from '~/stores/identityStore'

const route = useRoute()
const identity = identityStore()

const title: string = 'Klik hier om je coupon code in te voeren'
const text: string = 'Activeer direct jouw gratis lidmaatschap!'

const savedCoupon = useCoupon()
const showCoupon = computed(() => savedCoupon.value !== null)

const showBanner = computed(() => {
  return !identity.loggedIn && showCoupon.value && route.path !== '/signup'
})

const removeCoupon = () => {
  savedCoupon.value = null
}
</script>

<template>
  <div v-if="showBanner">
    <div
      class="pointer-events-none fixed inset-x-0 bottom-0 transition sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8"
    >
      <div
        class="pointer-events-auto flex items-center justify-between gap-x-6 bg-error/40 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5"
      >
        <p class="text-sm leading-6 text-gray-900">
          <NuxtLink to="/signup">
            <strong class="font-semibold">{{ title }}</strong>
            <svg viewBox="0 0 2 2" class="mx-2 inline size-0.5 fill-current" aria-hidden="true">
              <circle cx="1" cy="1" r="1" />
            </svg>
            <span>{{ text }}</span>
          </NuxtLink>
        </p>
        <button type="button" class="-m-1.5 flex-none rounded p-1.5 hover:bg-slate-100/30" @click="removeCoupon()">
          <span class="sr-only">Sluiten</span>
          <XMarkIcon class="size-5 text-gray-800" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>
