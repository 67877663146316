<script setup lang="ts">
const route = useRoute()
const appContainer = ref<HTMLElement | null>(null)

onMounted(() => {
  if (appContainer.value)
    appContainer.value.dataset.cyAppLoaded = 'true'
})

const testBuild = import.meta.env.VITE_TEST_BUILD === 'true'
const devBarHeight = ref<string>('0px')
if (testBuild)
  devBarHeight.value = '32px'

const analyticsScripts = () => {
  if (!testBuild) {
    // check if hotjar is enabled
    return `
    // hotjar start
   //    (function(h,o,t,j,a,r){
   //     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
   //     h._hjSettings={hjid:3765107,hjsv:6};
   //     a=o.getElementsByTagName('head')[0];
   //     r=o.createElement('script');r.async=1;
   //     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
   //     a.appendChild(r);
   // })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    // hotjar end
      `
  }
  return ''
}

useHead(() => ({
  htmlAttrs: {
    lang: 'nl',
  },
  link: [
    {
      rel: 'canonical',
      href: `https://ikwoonbeter.nl${route.path}`,
    },
  ],
  templateParams: {
    site: {
      name: 'ikwoonbeter',
    },
    separator: '-',
    subPage: null,
  },
  titleTemplate: '%s %separator %separator %site.name',
  script: [{ innerHTML: analyticsScripts() }],
}))
</script>

<template>
  <div class="min-h-screen text-neutral/70 antialiased">
    <BaseDevbar />
    <NavigationTop />    <div class="overflow-hidden">
      <div ref="appContainer" class="app-container">
        <NuxtPage />
      </div>
    </div>
    <NavigationFooter />
    <ClientOnly>
      <BannerCoupon />
    </ClientOnly>
    <FeedbackTrigger />
    <BaseNotificationList />
  </div>
</template>

<style>
.app-container {
  max-width: 1280px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  margin: 0 auto;
  min-height: calc(100vh - 60px - v-bind(devBarHeight));
}

.page-enter-active,
.page-leave-active {
  transition: opacity 0.12s;
}
.page-enter,
.page-leave-active {
  opacity: 0;
}
</style>
